.toggleBtn{
    font-weight: bold;
    text-transform: none;
}

.counter{
    font-size: 1em;
}

.actionBtn{
    position: relative;
    top: 100;
    right:0;
    z-index: 1;
    float: right;
}